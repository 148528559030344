export const NAV_LIST = [
  {
    label:'о проекте',
    href:'about',
    disabled:false
  },
  {
    label:'расширенный поиск',
    href:'adv-search',
    disabled:false
  },
  {
    label:'project-logo',
    href:'/',
    disabled:false
  },
  {
    label:'новости\nпроекта',
    href:'news',
    disabled:false
  },
  {
    label:'блог',
    href:'blog',
    disabled:false
  },
  // {
  //   label:'Источники',
  //   href:'all-sources',
  //   disabled:false
  // },
]