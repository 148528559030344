import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const HashRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hashPath = location.hash.replace('#', '');
    if (hashPath) {
      navigate(hashPath, { replace: true });
    }
  }, []);

  return null;
};

export default HashRedirect;