export const ABOUT_CONTENT = [
  `В базе данных <i>PhytoLex</i> представлены материалы по проекту "Растения и люди в Российской империи XVIII века: сословная дистрибуция знаний и практик», реализуемом в <a href="https://eusp.org/mast/about">Прикладном центре машинного обучения, анализа данных и статистики Европейского университета в Санкт-Петербурге</a>. Проект направлен на изучение растений и их роли в жизни человека, а также предписаний и практик, извлеченных из рукописных и печатных источников. Информация о растениях в базе включает сведения об их номинации, внешнем виде, использовании, географии произрастания и торговли.`,
  `Источниками базы являются рукописи и издания XI-XVIII веков различных жанров (прежде всего, пособия по ботанике, медицине, ветеринарии и этнографии), а также гербарии XVIII века из собрания БИН РАН.`,
  `<a target="_blank" href="/#/all-sources" >К списку источников</a>`,
  `Единицей описания в базе данных является название растения или его частей, таких как: плод (<i>яблоко, кокосъ</i>), зерно (<i>тминъ, кофе</i>), сок (<i>березовица, кленовика</i>) и т.д., а также образованные от них прилагательные (<i>бадьяновый, огуречный</i>). Условием вхождения фитонима в базу является его написание кириллицей, что может свидетельствовать о первом этапе его адаптации в языке. Гиперонимы и обобщенные названия частей растений типа «дерево», «ветвь», «лист» не включаются, однако входят такие названия частей растений, которые могут быть атрибутированы максимум четырем родам или видам (например, <i>желудь</i> - плод дуба, бука или каштана). В том случае, если растение описано, но не названо, в поле «Лексема» ставится прочерк.`,
  `Названия грибов и водорослей - а также сами эти слова - включаются в базу в связи с тем, что в народной таксономии они скорее объединяются с растениями, а также вследствие отсутствия специальных исследований об их использовании в исследуемый период.`,
  `<span>Текстовые фрагменты, в которых содержатся фитонимы, передаются на усмотрение куратора:</span><ol><li>в максимальном приближении к написанию в рукописном источнике;</li><li>в максимальном приближении к написанию в печатном издании XVIII века;</li><li>в упрощенной орфографии;</li><li>в виде скриншота PDF-файла с текстом источника.</li></ol>`,
  `Поиск на главной странице осуществляется по русскому или латинскому названию растения в соответствии с современной классификацией и по названиям растений, принятых в XVIII веке. Расширенный поиск, кроме того, включает возможность выбора из множества других параметров: функции растения, используемой части, способу обработки и запасания, источнику текста и т.д.`,
  `Идентификация растений производилась на основе ботанических баз данных Plants of the World Online и GBIF. Современные русские таксономические названия даны в соответствии с Wikipedia.`,
  `Список этнонимов приводится по исследованиям: Система научного описания музейного предмета: классификация, методика, терминология. Кн. 1. СПб., 2017; Вахтин Н.Б. Коренное население Крайнего Севера Российской Федерации. СПб., 1993. С. 9-10; З.П. Соколова, В.А. Туголуков. Старые и новые названия народов Севера // Советская этнография. 1983. № 1. С. 76-87.`,
  `Функции растений по возможности стандартизованы, однако в связи со сложностью интерпретации исторического материала некоторые из них даны в той формулировке, которая использована в источнике (поле «Функция как в источнике»). Аналогично, в двух вариантах, даны сведения об используемой части растения.`,
  `Особую сложность представляет интерпретация заболеваний людей и животных, в связи с чем они передаются только так, как в источнике.`,
  `Лингвистическая информация о названиях растений позволяет осуществлять поиск по языковой принадлежности фитонима, указанной в источнике, частеречной характеристике, этимону и наличию переносного значения. Фитонимы в базе приводятся в упрощенной орфографии, приближенной к норме Словаря русского языка XI-XVII вв. При наличии вариантов происходит унификация окончаний прилагательных; не учитывается отсутствие мягкого знака (<i>яблонка</i> дается как <i>яблонька</i>). В составных номинациях сохраняется порядок слов.`,
  `Этноботаническая база данных <i>PhytoLex</i> предназначена для антропологов, историков, филологов, ботаников, интересующихся особенностями номинации и использования растений в Древней Руси, Московской Руси и Российской империи XVIII века.`,
];